import * as React from "react";
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Spinner } from 'react-bootstrap';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';

const CheckoutForm = (props) => {
  const [error, setError] = useState(null);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    setIsProcessingPayment(true);
    props.handleProcessing(true);
    event.preventDefault();
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card)
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
      setIsProcessingPayment(false);
      props.handleProcessing(false);
    } else {
      setError(null);
      // Send the token to your server.

      stripeTokenHandler(result.token, props, errorHandler);
    }
  };

  const errorHandler = (resp) => {
    setIsProcessingPayment(false);
    props.handleProcessing(false);
    setError(resp.error.message);
  }

  let processingPaymentDiv = (
    <button type="submit">Pay</button>
  )

  if (isProcessingPayment) {
    processingPaymentDiv = (
      <Spinner animation="border" style={{marginTop: '10px'}}/>
    )
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="stripe-form">
        <div className="row">
          <div className="field">
            <CardNumberElement onChange={handleChange} options={{placeholder: "Card Number"}}/>
          </div>
          <div className="field half-width">
            <CardExpiryElement onChange={handleChange} options={{placeholder: "Expiration"}}/>
          </div>
          <div className="field half-width">
            <CardCvcElement onChange={handleChange} options={{placeholder: "CVC"}}/>
          </div>

          <div className="card-errors" role="alert">{error}</div>
        </div>

        {processingPaymentDiv}
      </form>
    </div>
  );
}

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const App = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props}/>
    </Elements>
  );
}

// POST the token ID to your backend.
async function stripeTokenHandler(token, props, errorHandler) {
  const response = await fetch('/charge', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({token: token.id, invoice: props.invoice})
  });

  response.json().then(function(resp) {
    if (!resp.success) {
      errorHandler(resp);
      return;
    }

    props.handleSubmit(resp)
  });
}

class StripeNode extends React.Component {
  render() {
    return <App {...this.props}/>
  }
}

export default StripeNode;