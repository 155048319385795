import * as React from "react";
import { Modal } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';


class PurchasedNode extends React.Component {
  showQRTimeout = null;

  constructor(props) {
    super(props)

    this.state = {
      node_ref: null,
      node_loading: false,
      hideClosingBtn: false,
      showModal: true,
      node_purchased: true
    }
  }

  handleCloseModal = () => {
    this.setState({
      node_ref: null,
      node_loading: false,
      hideClosingBtn: false,
      showModal: false,
      node_purchased: false
    })

    $('#invoice-animation-img').attr('src', '/img/invoice-animation-loop.gif'); // rewind gif to the start
    clearTimeout(this.showQRTimeout); // reset show qr on payment confirmed


  }

  showQR(){
    // show qr, after animation reaches its end
    this.showQRTimeout = setTimeout( () => {
      $('#qr-payment-confirmed-cont').removeClass('d-none');
    }, 2000);

  }

  stringCopied(string) {
    this.setState({[string]: true})

    setTimeout(() => {
      this.setState({[string]: false})
    }, 2000)

    return true;
  }

  renderPaymentConfirmedDiv() {
    const { invite } = this.props
    const { node_purchased } = this.state

    if (!node_purchased) {
      return null;
    }

    return (
      <div>
        <img id="payment-confirmed-img" src="/img/payment-confirmed-animation.gif" onLoad={this.showQR.bind(this)} />

        <div>
          <h3 id="dialog-title">Node Available</h3>
        </div>

        <div id="qr-payment-confirmed-cont" className="d-none">

          <div className="btn-continue-container">
          <QRCode value={invite.pin} size={123}/>
          </div>

          <div className="btn-continue-container">
          <CopyToClipboard text={invite.pin} onCopy={this.stringCopied.bind(this, 'invite_copied')}>
          <a href="#" id="invoice-string" className="invoice-string">{invite.pin.substr(0, 22)}...<span className="material-icons">filter_none</span></a>
          </CopyToClipboard>

          {this.state.invite_copied ? <p className="copied" style={{marginTop: '25px', color: '#dc3545'}}>Copied</p> : null}
          </div>

        </div>
        <div className="payment-confirmed-divs">
          <a style={{visibility: 'hidden'}} href="#" id="share-btn" ><img src="/img/share-btn.svg" alt="Share btn"/><span>Share</span></a>
        </div>
      </div>
    )
  }

  render() {
    const { node } = this.props
    const { node_purchased, hideClosingBtn, showModal } = this.state

    let closingBtn = null;
    if (!hideClosingBtn) {
      closingBtn = (
        <button type="button" onClick={this.handleCloseModal} className="close" data-dismiss="modal"><span className="material-icons">close</span></button>
      )
    }

    let inviteModal = null;
    if (node) {
      inviteModal = (
        <Modal show={showModal} size="sm" onHide={this.handleCloseModal} dialogClassName={node_purchased ? "payment-confirmed" : "invoice"} id="pay-in-sat">
          {closingBtn}

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 nopadding invoice-cont">
              {this.renderPaymentConfirmedDiv()}
            </div>
          </div>
        </Modal>
      )
    }

    return (
      <div>
        {inviteModal}
      </div>
    )
  }
}

export default PurchasedNode;
