import * as React from "react";
import QRCode from 'qrcode.react';


class FreeNode extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hideClosingBtn: false,
      showModal: true
    }
  }

  handleCloseModal = () => {
    this.setState({
      hideClosingBtn: false,
      showModal: false
    })

    $('#invoice-animation-img').attr('src', '/img/invoice-animation-loop.gif'); // rewind gif to the start
  }

  reloadInvite = () => {
    location.reload()
  }

  renderPaymentConfirmedDiv() {
    const { invite } = this.props
    const { node_purchased } = this.state

    return (
      <div>
        <QRCode value={invite.pin} size={284} fill="white"/>
      </div>
    )
  }

  render() {
    const { node } = this.props
    const { } = this.state

    let inviteModal = null;
    if (node) {
      inviteModal = this.renderPaymentConfirmedDiv()
    }

    return (
      <div>
        {inviteModal}
      </div>
    )
  }
}

export default FreeNode;
