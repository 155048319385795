import * as React from "react";
import { Modal } from 'react-bootstrap';
import axios from 'axios'
import QRCode from 'qrcode.react';
import StripeNode from './BuyNodesStripe'
import { MobileView, BrowserView } from "react-device-detect";
import { CopyToClipboard } from 'react-copy-to-clipboard';


class ChooseNode extends React.Component {
  timer = null;
  countdownTimer = null;

  reserveNodeAnimationTimeout = null;
  showQRTimeout = null;

  constructor(props) {
    super(props)

    this.state = {
      invite: null,
      payment_type: null,
      purchasing_node: false,
      node_ref: null,
      node_loading: false,
      node: null,
      minutes: 10,
      seconds: 0,
      stripe_node: false,
      hideClosingBtn: false,
      hideModal: false
    }
  }

  isInviteReady(invite) {
    if (!invite) {
      return false
    }
    return (invite.invite_status == 'ready' || invite.invite_status == 1)
  }

  checkPurchasedNode = () => {
    const { invoice, invite, node_purchased } = this.state

    if (node_purchased && invite) {
      return
    }

    const params = {
      invoice: invoice
    }

    axios.post(`/invoices/status`, params).then(response => {
      const invite = response.data.response

      if (this.isInviteReady(invite)) {
        this.setState({
          node_purchased: true,
          invite: invite
        })

        clearInterval(this.timer);
        clearInterval(this.countdownTimer)
      }
    })
  }

  processInvoice(invoice) {
    this.setState({ invoice: invoice })

    this.timer = setInterval(() => this.checkPurchasedNode(), 3000)
    this.countdownTimer = setInterval(() => {
      const { seconds, minutes } = this.state

      if (seconds > 0) {
          this.setState(({ seconds }) => ({
              seconds: seconds - 1
          }))
      }
      if (seconds === 0) {
          if (minutes === 0) {
            document.location.reload();
          } else {
              this.setState(({ minutes }) => ({
                  minutes: minutes - 1,
                  seconds: 59
              }))
          }
      }
    }, 1000)
  }

  onNodeBuy = (response) => {
    if (!response.data.success) {
      this.handleCloseModal();
      alert(response.data.error.message);
      return
    }

    const { invoice } = response.data.response

    this.processInvoice(invoice);
  }

  urlActionParam(){
    const p = new URLSearchParams(window.location.search)
    const activity = p.get('activity')

    if (activity) {
      return activity;
    }

    const action = p.get('action');
    const uuid = p.get('uuid');
    const host = p.get('host');

    if (!action || !uuid || !host) {
      return '';
    }

    return "join_tribe:" + uuid + ":" + host;
  }

  handlePurchaseNodeSats() {
    this.setState({ purchasing_node: true, payment_type: 'sats' })

    var action = this.urlActionParam()
    axios.get(`/nodes/buy_sats?type=${this.props.nodeType}${action?('&activity='+action):''}`).then(this.onNodeBuy)

    return false;
  }

  handlePurchaseNodeUsd() {
    this.setState({ purchasing_node: true, payment_type: 'usd' })

    var action = this.urlActionParam()
    axios.get(`/nodes/buy_usd?type=${this.props.nodeType}${action?('&activity='+action):''}`).then(this.onNodeBuy)

    return false;
  }

  handleShareBtn() {
    if (!navigator.share) {
      console.log("share not supported");
      return;
    }

    navigator.share({
      title: 'Invite to Sphinx',
      text: this.state.invite.pin
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }

  handleCloseModal = () => {
    if (this.state.invite && this.state.invite.pin) {
      const confirm = window.confirm("Did you copy the invite pin? If you close this window the pin will be gone.");
      if (confirm) {
        document.location.reload()
        return true;
      }

      return false;
    } else if (this.state.invoice) {
      axios.post(`/invoices/cancel?invoice=${this.state.invoice}`)
    }

    clearInterval(this.timer);
    clearInterval(this.countdownTimer)

    this.timer = null;
    this.countdownTimer = null;

    this.setState({
      invite: null,
      payment_type: null,
      purchasing_node: false,
      node_ref: null,
      node_loading: false,
      node: null,
      minutes: 10,
      seconds: 0,
      hideClosingBtn: false,
      hideModal: true,
      invoice: null
    })

    $('#invoice-animation-img').attr('src', '/img/invoice-animation-loop.gif'); // rewind gif to the start
    clearTimeout(this.reserveNodeAnimationTimeout); // reset hide animation
    clearTimeout(this.showQRTimeout); // reset show qr on payment confirmed
  }

  stripePayment(resp) {
    if (!resp.success) {
      return;
    }

    this.stripeProcessing(false)
    this.setState({
      node_purchased: true,
      invite: resp.response.invite
    })
  }

  stripeProcessing(hideClosingBtn) {
    this.setState({ hideClosingBtn: hideClosingBtn })
  }

  renderPaymentPendingDiv() {
    const { payment_type, invoice, minutes, seconds, node_purchased } = this.state

    if (node_purchased) {
      return null;
    }

    if (payment_type == 'usd') {
      return (
        <div>
          <img id="invoice-animation-img" src="/img/invoice-animation-loop.gif" onLoad={this.hideReservingNodeAnimation.bind(this)} />
          <div className="invoice-wrap opacity-0">
            <div>
              <h3 id="dialog-title">Card Details</h3>
            </div>

            <div className="expires-in">
              <span id="expires-in-label">Expires in: </span><span id="expires-in-value">{minutes}:{seconds < 10 ? ('0' + seconds) : seconds}</span>
            </div>

            <div className="btn-continue-container">
              <StripeNode handleProcessing={this.stripeProcessing.bind(this)} handleSubmit={this.stripePayment.bind(this)} invoice={invoice}/>
            </div>
          </div>

        </div>
      )
    }

    return (
      <div>
         <img id="invoice-animation-img" src="/img/invoice-animation-loop.gif" onLoad={this.hideReservingNodeAnimation.bind(this)} />
         <div className="invoice-wrap opacity-0">
          <div>
            <h3 id="dialog-title">Invoice</h3>
          </div>

          <div className="expires-in">
            <span id="expires-in-label">Expires in: </span><span id="expires-in-value">{minutes}:{seconds}</span>
          </div>

          <div className="btn-continue-container">
            <QRCode value={invoice} size={223}/>
          </div>

          <div className="btn-continue-container">
            <CopyToClipboard text={invoice} onCopy={this.stringCopied.bind(this, 'invoice_copied')}>
              <a href="#" id="invoice-string" className="invoice-string">{invoice.substr(0, 22)}...<span className="material-icons">filter_none</span></a>
            </CopyToClipboard>

            {this.state.invoice_copied ? <p className="copied" style={{marginTop: '25px', color: '#dc3545'}}>Copied</p> : null}
          </div>
        </div>
      </div>
    )
  }

  hideReservingNodeAnimation(){
    // hide the animation after it reaches its end
    this.reserveNodeAnimationTimeout = setTimeout( () => {
      $('#invoice-animation-img').hide();
      $('.invoice-wrap').removeClass('opacity-0').addClass('opacity-1');
    }, 5600);

  }

  showQR(){
    // show qr, after animation reaches its end
    this.showQRTimeout = setTimeout( () => {
      $('#qr-payment-confirmed-cont').removeClass('d-none');
    }, 2000);

  }

  stringCopied(string) {
    this.setState({[string]: true})

    setTimeout(() => {
      this.setState({[string]: false})
    }, 2000)

    return true;
  }


  renderPaymentConfirmedDiv() {
    const { invite, node_purchased } = this.state

    if (!node_purchased) {
      return null;
    }

    return (
      <div>
        <img id="payment-confirmed-img" src="/img/payment-confirmed-animation.gif" onLoad={this.showQR.bind(this)} />

        <div>
          <h3 id="dialog-title">Payment confirmed</h3>
        </div>

        <div id="qr-payment-confirmed-cont" className="d-none">

          <div className="btn-continue-container">
          <QRCode value={invite.pin} size={123}/>
          </div>

          <div className="btn-continue-container">
          <CopyToClipboard text={invite.pin} onCopy={this.stringCopied.bind(this, 'invite_copied')}>
          <a href="#" id="invoice-string" className="invoice-string">{invite.pin.substr(0, 22)}...<span className="material-icons">filter_none</span></a>
          </CopyToClipboard>

          {this.state.invite_copied ? <p className="copied" style={{marginTop: '25px', color: '#dc3545'}}>Copied</p> : null}
          </div>

        </div>
        <div className="payment-confirmed-divs">
          {/* <MobileView>
            <a href="#" id="share-btn" ><img src="/img/share-btn.svg" alt="Share btn"/><span>Share</span></a>
          </MobileView>
          <BrowserView> */}
            <a style={{visibility: 'hidden'}} href="#" id="share-btn" ><img src="/img/share-btn.svg" alt="Share btn"/><span>Share</span></a>
          {/* </BrowserView> */}
        </div>
      </div>
    )
  }

  render() {
    const { nodeType, nodeMessages, nodeCapacity, nodePriceSats, nodePriceUsd, features } = this.props
    const { invoice, node_purchased, hideClosingBtn, purchasing_node } = this.state

    let closingBtn = null;
    if (!hideClosingBtn) {
      closingBtn = (
        <button type="button" onClick={this.handleCloseModal} className="close" data-dismiss="modal"><span className="material-icons">close</span></button>
      )
    }

    let inviteModal = null;
    if (invoice) {
      inviteModal = (
        <Modal show={true} size="sm" onHide={this.handleCloseModal} dialogClassName={node_purchased ? "payment-confirmed" : "invoice"} id="pay-in-sat">
          {closingBtn}

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 nopadding invoice-cont">
              {this.renderPaymentPendingDiv()}
              {this.renderPaymentConfirmedDiv()}
            </div>
          </div>
        </Modal>
      )
    } else if (purchasing_node) {
      inviteModal = (
        <Modal show={true} size="sm" onHide={this.handleCloseModal} dialogClassName="invoice" id="pay-in-sat">
          {closingBtn}

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 nopadding invoice-cont">
              <div>
                <img id="invoice-animation-img" src="/img/invoice-animation-loop.gif" onLoad={this.hideReservingNodeAnimation.bind(this)} />
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    const period = (nodeType.toLowerCase() == 'lite' ? '/ 1 time' : '/ mon');

    return (
      <div>
        {inviteModal}

        <div className="item large-item">
          <img className={"hosting-plan-type " + nodeType.toLowerCase() }  src={"/img/" + nodeType.toLowerCase() + "-hosting-plan.svg"} />
          <h3>{nodeType}</h3>
          <div className="messages-capacity">
            {features.map(function(feat, idx) {
              return (
                <div key={idx}>
                  <di dangerouslySetInnerHTML={{__html: feat}}></di>
                  { (idx == features.length-1) ? '' : <hr/> }
                </div>
              )
            })}
          </div>

          <a href="javascript:void(0);" onClick={this.handlePurchaseNodeSats.bind(this)} className="blue-btn buttons"><span className="symbol">sat</span> <span className="value">{nodePriceSats}</span> <span className="period">{period}</span></a>
          <div className="or"><span>or</span></div>
          <a href="javascript:void(0);" onClick={this.handlePurchaseNodeUsd.bind(this)} className="green-btn buttons"><span className="symbol">$</span> <span className="value">{nodePriceUsd}</span> <span className="period">{period}</span></a>

          <p className="nodes-available">
            <strong>{this.props.nodes_available}</strong> available
          </p>
        </div>
      </div>
    )
  }
}

export default ChooseNode;
